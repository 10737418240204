(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerServerConfig', ['$sce', filemakerServerConfig]);

	function filemakerServerConfig($sce) {
		return {
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function config() {
			return {
				id: 2,
				name: 'FileMaker CWP',
				propertyName: 'filemakerServer',
				getApiConnection: null,
				available: true,
				editable: true,
				isPrimary: true,
				async: true,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/FileMakerBigAlt.png`,
				sourceHelp: $sce.trustAsHtml(
					'<h4>These settings will tell DayBack how to communicate with your hosted FileMaker file.</h4>DayBack requires that your file be hosted with FileMaker Server version 12 or higher and that you have access to place a simple PHP relay file in the server\'s web publishing folder. (A link to download that relay file is provided below.)<br><br>You should see your events in DayBack as soon as you complete the settings on this page and on the next tab labeled "Field Mapping". Click "Details" for more information about any item below; more details (and video) can be found in our docs here: <a href="https://docs.dayback.com/article/126-integration" target="blank">detailed instructions</a>.<br><br> And please <a href="https://dayback.com/contact/" target="blank">let us know</a> if you have any questions about this form.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>Please map each of the fields below to a field in your events table.</h4>If you don't have a particular field, or don't want your calendar users to see the field, just ​un-check​ the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work. Note that any fields you reference below will need to be on the \"Source layout name\" layout you specified above."
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				useISOTimestamp: false,
				customActionsEditable: true,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 2,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New FileMaker Calendar',
						format: 'name',
						helptext:
							'Your own name for these events, like "Company Projects".',
					},
					server: {
						setting: 'serverAddress',
						visible: true,
						displayValue: 'Server address',
						defaultValue: 'YourFileMakerServer.com',
						helptext:
							'The public address of your FileMaker Server in the form myserver.com or 123.456.789.012. This is the address you use when opening files from outside your local network using File / Open Remote.',
					},
					protocol: {
						setting: 'protocol',
						visible: true,
						displayValue: 'Server protocol',
						defaultValue: 'https',
						helptext:
							'http or https if you have an SSL cert installed on your FileMaker Server.',
					},
					port: {
						setting: 'port',
						visible: true,
						displayValue: 'Server port',
						defaultValue: 443,
						helptext:
							"Most likely 80 if you're using http, 443 if you're using https.",
					},
					phpRelay: {
						setting: 'phpRelay',
						visible: true,
						displayValue: 'PHP relay file name',
						defaultValue: 'fmxjRelay.php',
						helptext:
							'The name of your php relay file; this file is originally named "fmxjRelay.php" but you\'ll want to give it your own name.<br><br> \
						The PHP relay file lets you hard-code login information used by DayBack into a file on your server... so this account information isn\'t stored on DayBack\'s servers or shared with DayBack. You can download the <a href="updates/downloads/fmxjRelay.zip" target="blank">PHP relay file here</a>, rename it, and edit your account information by changing a couple lines to reflect a FileMaker account name and password that have read/write access to your FileMaker file. DayBack will pass the logged in DayBack user\'s name (often the user\'s email address) into the relay: your edits to the relay file then pass the FileMaker account name and password for that user into FileMaker. Here are the lines you\'ll find in the relay file to change:<br><br><div class="exampleCode">' +
							'<span class="comment">//Set to false if you plan on having all users logged into FMS with the same account</span><br>' +
							'$loginByName = false;<br><br>' +
							'if ($loginByName){<br>' +
							'<span class="comment">//set credentials based on the user\'s DayBack account name / email address.</span><br>' +
							'&nbsp; if ($u==="DayBackUser@somedomain.com") {<br>' +
							'&nbsp; &nbsp; $u==="FileMakerUser"<br>' +
							'&nbsp; &nbsp; $p = "FileMakerPassword";<br>' +
							'&nbsp; }<br>' +
							'&nbsp; else if ($u==="AnotherDayBackUser@somedomain.com") {<br>' +
							'&nbsp; &nbsp; $u==="AnotherFileMakerUser";<br>' +
							'&nbsp; &nbsp; $p==="AnotherFileMakerPassword";<br>' +
							'&nbsp; }<br>' +
							'}<br>' +
							'<span class="comment">//hardcoded credentials for all users</span><br>' +
							'else {<br>' +
							'&nbsp; $u="accountname";<br>' +
							'&nbsp; $p="password";<br>' +
							'}<br>' +
							'</div><br>More details about these edits, how this works, and specific locations for where to place the file on your server can be found here: <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackOnline.PHPrelayFile" target="blank">What is the PHPrelay?</a><br><br>Be sure that any FileMaker privilege sets used for DayBack users have the fmxml Extended Privilege enabled (to permit xml web publishing). Once you\'ve edited the file, install it in your FileMaker Server\'s Web Publishing Engine (WPE) root directory. This will need to be installed before DayBack can work with your FileMaker files.',
					},
					fileName: {
						setting: 'fileName',
						visible: true,
						displayValue: 'FileMaker file name',
						defaultValue: 'YourFile.fmp12',
						helptext:
							'The name of your FileMaker file with the .fmp12 extension.',
					},
					layoutName: {
						setting: 'layoutName',
						visible: true,
						displayValue: 'Source layout name',
						defaultValue: 'Your Layout Name',
						helptext:
							'Each table of events you\'d like to see in DayBack requires its own layout (and its own source entry here). If you\'re using DayBack.fmp12 or have DayBack integrated into your FileMaker Pro solution then use one of your "Source No X" layouts here.<br><br> \
						If you\'re not using DayBack in your solution already, please make a new layout for this. Here are a few things to double check on this new layout <br> \
						<ul><li>The layout should be based on the events table you wish to publish (it should the table that contains the "Event id" field you specify below.)</li> \
						<li>The layout should contain NO script triggers and no fields other than the ones specific in "Field Mapping" below.</li> \
						<li>The layout should contain ALL the fields mentioned in "Field Mapping" below.</li> \
						<li>The privilege set used for the account name and password above (in your PHP relay file) must have read/write access to this layout.</li></ul>',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: true,
						displayValue: "FileMaker file's date format",
						defaultValue: 'MM/DD/YYYY',
						helptext:
							'This is the format DayBack will use when writing event changes back to your FileMaker file. Settings for how <em>dates are displayed</em> can be found in Admin Settings under "Date / Time Formats".<br><br> \
						For files created in the United States this format is probably MM/DD/YYYY. For files created in the EU it is likely DD/MM/YYYY. If you get this correct you\'ll be able to drag events from one date to another without issue. If this format is incorrect, dragging an existing event to a new date will throw the following error, telling you that you\'ve got the format wrong:<br><br><div class="exampleCode"> \
								"There was an error saving the event and your changes will be reverted: 500 - Date value does not meet validation entry options" \
							<br><br></div>',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: false,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: false,
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'contact', label: 'Contact'},
							{id: 'project', label: 'Project'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
				},
			};
		}

		function fieldMap() {
			return {
				recordID: {
					setting: 'recordID',
					visible: false,
					defaultValue: '-recid',
				},
				allDay: {
					setting: 'allDay',
					visible: true,
					displayValue: 'All Day Event',
					defaultValue: '',
					required: false,
					unused: false,
					helptext: '',
				},
				eventID: {
					setting: 'eventID',
					visible: true,
					displayValue: 'Event id',
					defaultValue: 'id',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				dateStart: {
					setting: 'dateStart',
					visible: true,
					displayValue: 'Date start',
					defaultValue: 'DateStart',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'The start date for your event: must be a date field, not a timestamp or text field. Note that if you only have one date for your event (a deadline, for example) that goes here.',
				},
				dateEnd: {
					setting: 'dateEnd',
					visible: true,
					displayValue: 'Date end',
					defaultValue: '',
					hideAdvanced: true,
					required: false,
					unused: false,
					helptext:
						'The end date for your event: must be a date field, not a timestamp. If you do not have an end date (or only have one date for your event) please create a field for this anyway.',
				},
				timeStart: {
					setting: 'timeStart',
					visible: true,
					displayValue: 'Time start',
					defaultValue: 'TimeStart',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'The time your event begins: must be a time field. Note that if you only have one time for your event (a deadline, for example) that goes here.',
				},
				timeEnd: {
					setting: 'timeEnd',
					visible: true,
					displayValue: 'Time end',
					defaultValue: '',
					hideAdvanced: true,
					required: false,
					unused: false,
					helptext:
						'The end time for your event: must be a time field. If you do not have an end time (or only have one time for your event) please create a field for this anyway.',
				},
				start: {
					setting: 'start',
					visible: true,
					displayValue: 'TimestampNum calc start',
					defaultValue: 'DBk_TimestampStartCalcNum',
					labelValue: 'Start',
					required: true,
					unused: false,
					helptext:
						'The event start timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents::DBk_TimestampstartCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; ts = TimeStart  // Your event's start time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'&nbsp; Timestamp ( ds ; Max ( ts ; 0 ) ) <br>' +
						'&nbsp; ) <br>' +
						')<br><br><br>' +
						'</div>',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'TimestampNum calc end',
					defaultValue: '',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext:
						'The event end timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents::DBk_TimestampEndCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; de = DateEnd ; // Your event's end date field <br>" +
						"&nbsp; ts = TimeStart ; // Your event's start time field <br>" +
						"&nbsp; te = TimeEnd ; // Your event's end time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; spanmidnight = not IsEmpty ( te ) and te < ts <br><br>' +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'Timestamp ( <br>' +
						'&nbsp; If ( spanmidnight and IsEmpty ( de ) ; ds + 1 ; Max ( ds ; de ) ) <br>' +
						'; <br>' +
						'&nbsp; If ( IsEmpty ( te ) ; Max ( ts ; 0 ) ; te ) <br>' +
						'&nbsp; ) <br>' +
						'&nbsp; ) <br>' +
						')<br><br><br>' +
						'</div>',
				},
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Event display calc',
					defaultValue: 'DBk_EventSummaryCalc',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						"This is what shows as text for your event in the calendar before you click on an event; it's is how the event renders in the month view, for example. This is usually a calculated field combining the title of the event with some information about the event's description or related contact. If you're using DayBack.fmp12 this is the field SampleEvents::DBk_EventSummaryCalc and you can add that field to your table, or copy it's definition below.<br><br>You'll see that our example also <strong>substitutes carriage returns</strong> and you will want to do the same in your version of this field. Here is an example: <br><br><div class=\"exampleCode\">" +
						'Let ( [ <br><br>' +
						'// ===== Edit the lines below to reflect the fields in your events table ===== <br><br>' +
						'&nbsp; s = Summary ; // the title of your event <br>' +
						"&nbsp; desc = Description ;  // your event's description or notes field <br>" +
						"&nbsp; cont = SampleContacts::NameFull ; // your contact's name (optional) <br>" +
						"&nbsp; ph = SampleContacts::Phone ; // your contact's phone number (optional) <br>" +
						"&nbsp; em = SampleContacts::Email ; // your contact's email (optional) <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; d = Left ( desc ; 300  ) <br><br>' +
						']; <br><br>' +
						'// ===== Use the list function to concatenate different fields together ===== <br><br>' +
						'// ===== Then substitute out any carriage returns ===== <br><br>' +
						'Substitute ( List ( s ; cont ; ph ;  em ; d ) ; "¶" ; "\n" ) <br><br>' +
						')<br><br><br>' +
						'</div>',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Summary',
					defaultValue: 'Summary',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext:
						'This is the field shown in the event popover as the title or summary; this field should be editable (i.e. not a calc).',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: '',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext:
						'This is the field shown in the event popover as the description (a longer text field); this field should be editable (i.e. not a calc).',
				},
				location: {
					setting: 'location',
					visible: true,
					displayValue: 'Location',
					defaultValue: '',
					labelValue: 'Location',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "location" --this is also the field DayBack will use to generate a map.',
				},
				geocode: {
					setting: 'geocode',
					visible: false,
					displayValue: 'GeoCode',
					defaultValue: '',
					labelValue: 'GeoCode',
					required: false,
					unused: false,
					helptext:
						'Latitude and longitude values separated by a comma',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource',
					defaultValue: '',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext:
						'The field that holds your "resource" which could be a person, a room, or a piece of equipment. If you don\'t have a resource field, please create one.',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: '',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "status" --this is also the field DayBack will use to color code your events. This need not be a literal status and can be any field you\'d like to color by provided the field isn\'t used elsewhere in this list.',
				},
				tags: {
					setting: 'tags',
					visible: true,
					displayValue: 'Tags',
					defaultValue: '',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "tags" --this is also the field DayBack will use to certain meta-data about your events.',
				},
				contactID: {
					setting: 'contactID',
					visible: true,
					displayValue: 'Contact id',
					defaultValue: '',
					required: false,
					unused: false,
					related: 'contactName',
					helptext:
						"Optional: If your event is linked to a contact, this is the field where you store the event's contact ID. (This is the field in your events table that holds the id for the event's project.)",
				},
				contactName: {
					setting: 'contactName',
					visible: true,
					displayValue: 'Contact name',
					defaultValue: '',
					labelValue: 'Contact',
					required: false,
					unused: false,
					related: 'contactID',
					helptext:
						"Optional: If you mapped a contact ID above, this is the related field which stores the contact's name. For example: SampleContacts::NameFull.",
				},
				projectID: {
					setting: 'projectID',
					visible: true,
					displayValue: 'Project id',
					defaultValue: '',
					required: false,
					unused: false,
					related: 'projectName',
					helptext:
						"Optional: If your event is linked to a project, this is the field where you store the event's project ID. (This is the field in your events table that holds the id for the event's project.)",
				},
				projectName: {
					setting: 'projectName',
					visible: true,
					displayValue: 'Project name',
					defaultValue: '',
					labelValue: 'Project',
					required: false,
					unused: false,
					related: 'projectID',
					helptext:
						"Optional: If you mapped a project ID above, this is the related field which stores the project's name. For example: SampleProjects::Name.",
				},
			};
		}

		function unusedMap() {
			return {
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}

		function hiddenFieldMap() {
			return {};
		}

		function readOnlyFieldMap() {
			return {};
		}
	}
})();
